import React from "react";
import Menu from "../components/Navbar";
import About from "../components/About";
import Footer from "../components/Footer";

const AboutUs = () => {

  return (
    <div>
      {/* Navbar Component*/}
      <Menu />

      <div className="page-margin">
        {/*breadcrumb start*/}
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span>À propos de nous</span>
                </h2>
              </div>
              <div className="col-md-6 col-sm-6 col-text-center">
                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                  <ol className="breadcrumb bg-transparent mb-0">
                    <li className="breadcrumb-item">
                      <a href={`/home`}>Accueil</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a
                        href="javascript"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        À propos de nous
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/*breadcrumb end*/}
      </div>

      {/* About Component*/}
      <About isOnly={true} />

      {/*Footer Component*/}
      <Footer />
    </div>
  );
};

export default AboutUs;
