import React, { useEffect } from "react";

const Footer = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div>
      <section className="p-0">
        <div className="container-fluid ">
          <div className="bottom-section">
            <div className="row footer-french-tontine p-5 justify-content-center">
              <div className="col-md-3 pl-2 pr-2 pt-4">
                <div>
                  <h2 class="underline-title">Contacts</h2>
                  <ul className="footer-style">
                    <li>
                      <div className="footer-icon">
                        <img src={`/assets/images/locate.png`} alt="locate" />
                      </div>
                      <div className="footer-address">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Montpellier, France
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="footer-icon">
                        <img
                          src={`/assets/images/telephone.png`}
                          alt="telephone"
                        />
                      </div>
                      <div className="footer-address">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          +33 7 71 45 17 66
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="footer-icon">
                        <img
                          src={`/assets/images/fotter-email.png`}
                          alt="fotter-email"
                        />
                      </div>
                      <div className="footer-address">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          contact@lafrenchtontine.com
                        </a>
                      </div>
                    </li>
                    {/* <li>
                      <div className="footer-icon">
                        <img src={`/assets/images/fax.png`} alt="" />
                      </div>
                      <div className="footer-address">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          123-456 / 456-789
                        </a>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-md-3 pl-2 pr-2 pt-4">
                <div>
                  <h2 class="underline-title">à propos</h2>
                  <ul className="footer-style">
                    <li>
                      {/* <div className="footer-icon">
                          <img src={`/assets/images/locate.png`} alt="locate" />
                        </div> */}
                      <div className="footer-address">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Qui sommes-nous ?
                        </a>
                      </div>
                    </li>
                    <li>
                      {/* <div className="footer-icon">
                          <img
                            src={`/assets/images/telephone.png`}
                            alt="telephone"
                          />
                        </div> */}
                      <div className="footer-address">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Comment ça marche ?
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 pl-2 pr-2 pt-4">
                <div>
                  <h2 class="underline-title">Aide</h2>
                  <ul className="footer-style">
                    <li>
                      <div className="footer-address">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          FAQ
                        </a>
                      </div>
                    </li>
                    <li>
                      {/* <div className="footer-icon">
                          <img
                            src={`/assets/images/telephone.png`}
                            alt="telephone"
                          />
                        </div> */}
                      <div className="footer-address">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Mentions légales
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-md-3 pl-2 pr-2 pt-4">
                <div className="footer-french-tontine-container">
                  <div>
                    <h2 class="underline-title">Presse</h2>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-section index-footer">
        {/* <p>{new Date().getFullYear()} copyright by themeforest powered by pixel strap</p> */}
        <p>© {new Date().getFullYear()}, Fait avec ❤️ par Ets Lundasolutions</p>
      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/*Your customer chat code*/}
      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#7239E4"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      ></div>
    </div>
  );
};

export default Footer;
