import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  return (
    <section className="authentication-form">
      <div className="innerpage-decor">
        <div className="innerpage-circle1">
          <img src={`/assets/images/Testimonial2.png`} alt="" />
        </div>
        <div className="innerpage-circle2">
          <img src={`/assets/images/Testimonial1.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              

              <div className="centered-text">
                <h1 style={{ fontSize: 45 }}>
                  <span className="f-bold main-title">La french</span>
                </h1>

                <h1 style={{ fontSize: 45 }}>
                  <span className="f-bold f-color main-title">tontine</span>
                </h1>
                

                <img
                className="home-slogan"
                src="/assets/images/french-tontine.png"
                style={{
                  zIndex: '1',
                  // width: '45vw',
                  objectFit: 'cover',
                }}
                alt=""
              />
                {/* <div style={{ height: '30vh' }}>
                </div> */}

                
                <h1 style={{ fontSize: 30 }}>
                  <span className="f-bold f-color" >S'unir pour mieux se développer</span>
                </h1>
              </div>

              {/* <div 
                className="col-lg-8 offset-lg-2" 
                style={{ 
                  backgroundImage: `url(/assets/images/french-tontine.png)`, 
                  backgroundSize: 'cover', 
                  backgroundRepeat: 'no-repeat', 
                  backgroundPosition: 'center', 
                  minHeight: '65vh', 
                  maxHeight: '35vh', 
                  maxWidth: '70vw' 
                }}
              > */}
              {/* <div className="col-lg-9 offset-lg-2" style={{ position: 'relative' }}> */}
                
                {/* <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '1',
                    background: 'rgba(255, 255, 255, 0.50)',
                  }}

                  //className="home-slogan"
                >
                  <h1 style={{ fontSize: 30 }}>
                    <span className="f-bold f-color" >S'unir pour mieux se développer</span>
                  </h1>
                </div> */}
              {/* </div> */}

            </div>
          </div>
        </div>

        {/*copy-right-section*/}
        <footer className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="form-button text-center">
                  <a
                    href="/home"
                    className="btn btn-custom btn-lg theme-color btn-back"
                  >
                    en savoir plus <i className="fa fa-angle-double-right mr-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*end copy right section*/}
      </div>
    </section>
  );
};

export default Home;
