import React, { useEffect, useState } from "react";

const AboutOurValues = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(/assets/images/feature-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(/assets/images/feature-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(/assets/images/color/color-2/feature-bg1.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(/assets/images/color/color-3/feature-bg1.png)`,
      });
  }, [color]);

  return (
    <section id="feature" className="feature" style={bgImg}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img src={`/assets/images/feature2.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="feature-phone">
            <img src={`/assets/images/icon/our-values.png`} className="img-fluid" alt="" />
          </div>
          <div className="offset-lg-4 col-lg-8">
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title">
                  Quelles sont les valeurs de
                  <br /> la <span className="main-title"> FRENCH TONTINE ?</span>
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      {/* <img src={`/assets/images/icon/1.png`} height={80} alt="icon" /> */}
                      {/* <Icon
                        fontSize="30px"
                        icon="tabler:bell-ringing"
                        color="#7239E4"
                      /> */}
                    </div>
                    <div className="feature-subtitle">
                      <b className="us-value"> Solidarité</b>
                    </div>
                    <div>
                      <p>
                        construire une communauté solidaire et d'entraide mutuelle
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      {/* <img src={`/assets/images/icon/2.png`} height={80} alt="icon" /> */}
                      {/* <Icon
                        fontSize="30px"
                        icon="tabler:shield-dollar"
                        color="#7239E4"
                      /> */}
                    </div>
                    <div className="feature-subtitle">
                      <b className="us-value"> Confiance</b>
                    </div>
                    <div>
                      <p>
                        établir un climat de confiance entre particuliers, professionnels, communautés.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      {/* <img src={`/assets/images/icon/3.png`} height={80} alt="icon" /> */}
                      {/* <Icon
                        fontSize="30px"
                        icon="tabler:calendar-event"
                        color="#7239E4"
                      /> */}
                    </div>
                    <div className="feature-subtitle">
                      <b className="us-value"> Sécurité</b>
                    </div>
                    <div>
                      <p>
                        garantir un espace de sécurité pour tous.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 transparent-div">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      {/* <img src={`/assets/images/icon/4.png`} height={80} alt="icon" /> */}
                      {/* <Icon
                        fontSize="30px"
                        icon="tabler:cloud-dollar"
                        color="#7239E4"
                      /> */}
                    </div>
                    <div className="feature-subtitle">
                      <b className="us-value"> Entrepreneuriat</b>
                    </div>
                    <div>
                      <p>
                        pousser les utilisateurs à réaliser des projets innovants
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="top-margin ml-2">
                                <a
                                  href={`/home#contact `}
                                  className="btn btn-custom theme-color"
                                  role="button"
                                >
                                    Devenez membre
                                </a>
                            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutOurValues;
