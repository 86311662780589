import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://french-tontine-api.his-tech.tech', // https://french-tontine-api.his-tech.tech Set your base URL here
  // You can also define other custom configuration options
  timeout: 5000, // Request timeout
  headers: {
    'Content-Type': 'application/json'
  }
})

export default instance
