import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";

// import { useHistory } from "react-router";
const Profil = (props) => {
  const { isOnly = true } = props;

  // const history = useHistory();
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: "url(/assets/images/about-bg.png)",
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: "url(/assets/images/about-bg.png)",
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: "url(/assets/images/color/color-2/about-bg.png)",
      });
    else
      setBgImg({
        backgroundImage: "url(/assets/images/color/color-3/about-bg.png)",
      });
  }, [color]);

  
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [collapse, setCollapse] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const toggleMenu = (id) => {
    if (collapse === id) {
      setIsOpen(false);
      setCollapse(null);
    } else {
      setIsOpen(true);
      setCollapse(id);
    }
  };

  return (
    <>
      <section id="about" className="about" style={bgImg}>
        <div className="about-decor">
          <div className="about-circle1">
            <img src={`/assets/images/team1.png`} alt="team1" />
          </div>
          <div className="about-circle2">
            <img src={`/assets/images/main-banner1.png`} alt="banner1" />
          </div>
        </div>
        <div className="container">
          <div className="row ">
            <div className="col-md-5">
              <div className="about-contain">
                <div>
                  <h2 className="title">
                    <span>Notre public cible : </span>
                  </h2>
                  <div className="row sm-mb">
                    <div className="col-6">
                      <ul className="about-style">
                        <li className="abt-hover">
                          <div className="about-icon">
                            <div className="icon-hover">
                              <img
                                src={`/assets/images/icon1.png`}
                                alt="easy-to-customized"
                              />
                            </div>
                          </div>
                          <div className="about-text">
                            <h3>Entrepreneur</h3>
                          </div>
                        </li>
                        <li className="abt-hover">
                          <div className="about-icon">
                            <div className="icon-hover">
                              <img
                                src={`/assets/images/icon3.png`}
                                alt="easy-to-use"
                              />
                            </div>
                          </div>
                          <div className="about-text">
                            <h3>Particulier </h3>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="about-style">
                        <li className="abt-hover">
                          <div className="about-icon">
                            <div className="icon-hover">
                              <img
                                src={`/assets/images/icon2.png`}
                                alt="Awasome-Design"
                              />
                            </div>
                          </div>
                          <div className="about-text">
                            <h3>Etudiant</h3>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <img src={`/assets/images/down.png`} style={{ height: 50, marginLeft : 350, marginBottom : 15 }} alt="" />

                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="faq">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div id="accordion">
                  <div className="card border-theme mb-3 radius-0">
                    <div
                      className="card-header"
                      onClick={() => toggleMenu(2)}
                    >
                      <a
                        href="javascript"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className=""
                      >
                        <b>Êtes-vous entrepreneur ?</b>
                        
                        <i className="fa fa-angle-down"></i>
                      </a>
                    </div>
                    <Collapse isOpen={collapse === 2 ? isOpen : false}>
                      <div>
                        <div className="card-body">
                        Découvrez l'avenir du financement collaboratif avec French Tontine :

                        <br></br> <br></br> <b>Osez l'Innovation : </b> <br></br>
                        Nous sommes bien plus qu'une simple plateforme de financement. Nous sommes le catalyseur de l'innovation. Que vous soyez un créateur de tendances ou un visionnaire, French Tontine est votre plateforme pour transformer vos idées en réalité. Bousculez les conventions, repoussez les limites et forgez le chemin vers le succès. 
                        
                        <br></br> <br></br> <b>Construisez des Connexions Solides : </b> <br></br>
                        À French Tontine, nous croyons en la puissance des liens. Notre plateforme connecte des esprits similaires et des ambitions complémentaires. Les entrepreneurs talentueux rencontrent des investisseurs enthousiastes, créant ainsi des partenariats durables et des collaborations fructueuses. 
                        
                        <br></br> <br></br> <b>Sécurité à Toute Épreuve : </b> <br></br>
                        Votre tranquillité d'esprit est notre priorité. Nous avons mis en place des mesures de sécurité avancées pour protéger vos données et vos transactions. Concentrez-vous sur la réalisation de vos objectifs, en sachant que French Tontine veille sur vos intérêts. 
                        
                        <br></br> <br></br> <b>Un Impact Mesurable : </b> <br></br>
                        Rejoindre French Tontine signifie s'engager dans un voyage qui compte. Nous mettons en avant des projets porteurs de sens, ceux qui contribuent positivement à la société et à l'économie. Votre participation ne se limite pas à un simple investissement ; elle crée un impact réel et mesurable. 
                        
                        <br></br> <br></br> <b>Votre Succès, Notre Mission : </b> <br></br>
                        Nous sommes déterminés à faire de chaque entrepreneur un succès retentissant. Avec French Tontine, vous accédez à un réseau de ressources, de mentorat et de soutien qui vous propulse vers de nouveaux sommets. Votre succès est notre plus grande fierté.
                        Prêt à transformer vos rêves en réalité ? Rejoignez dès maintenant French Tontine et faites partie d'une communauté dynamique d'innovateurs, d'investisseurs et de créateurs de changement. Ensemble, nous redéfinissons la voie du financement et de l'entrepreneuriat. Explorez nos opportunités uniques, découvrez des projets passionnants et écrivons ensemble les chapitres captivants de votre succès sur la scène entrepreneuriale. La révolution entrepreneuriale commence ici. Bienvenue sur French Tontine.

                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="card border-theme mb-3 radius-0">
                    <div
                      className="card-header"
                      onClick={() => toggleMenu(3)}
                    >
                      <a
                        href="javascript"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className=""
                      >
                        <b>Êtes-vous particulier ?</b>
                        <i className="fa fa-angle-down"></i>
                      </a>
                    </div>
                    <Collapse isOpen={collapse === 3 ? isOpen : false}>
                      <div>
                        <div className="card-body">
                          La French Tontine est heureuse de vous présenter une nouvelle façon d'aborder l'épargne et le financement de vos projets personnels. Nous comprenons les défis auxquels vous pouvez être confrontés lorsqu'il s'agit de réaliser vos rêves et vos aspirations, et nous sommes là pour vous aider à les concrétiser.
                          
                          <br></br> <h3 className="custom-color">Problèmes rencontrés : </h3>
                          <ul>
                            <li>
                              •	<b>Financement Limité : </b> <br></br> Les ressources financières limitées peuvent entraver la réalisation de projets importants tels que la rénovation de votre maison, des vacances en famille inoubliables ou des projets en couple qui vous tiennent à cœur.
                            </li><br></br>
                            <li>
                              •	<b>Économies Insuffisantes : </b> <br></br> Les comptes d'épargne traditionnels ne permettent pas toujours de constituer rapidement les fonds nécessaires pour vos projets, en particulier en cas d'urgence.
                            </li><br></br>
                            <li>
                              •	<b>Gestion des Dépenses : </b> <br></br> La gestion quotidienne des finances peut rendre difficile la planification à long terme et la réalisation de vos objectifs financiers.
                            </li>
                          </ul>

                          <h3 className="custom-color">Solutions offertes par La French Tontine : </h3>
                          <ul>
                            <li>
                              •	<b>Financement Collaboratif : </b> <br></br> Rejoignez notre communauté d'épargnants pour contribuer collectivement à la réalisation de vos projets. Profitez de cotisations régulières pour atteindre rapidement vos objectifs financiers.
                            </li><br></br>
                            <li>
                              •	<b>Accès aux Fonds Facile : </b> <br></br> Avec La French Tontine, vous pouvez accéder rapidement aux fonds nécessaires pour votre projet, sans tracas ni délais excessifs.
                            </li><br></br>
                            <li>
                              •	<b>Sécurité et Transparence : </b> <br></br> Nous mettons l'accent sur la sécurité et la conformité réglementaire pour que vous puissiez épargner en toute confiance.
                            </li>
                          </ul>

                          <h3 className="custom-color">Les possibilités de financement avec La French Tontine : </h3>
                          <ul>
                            <li>
                              •	<b>Rénovation de Votre Maison : </b> <br></br> Transformez votre maison en un espace qui vous ressemble, sans attendre des années pour économiser.
                            </li><br></br>
                            <li>
                              •	<b>Vacances en Famille Inoubliables : </b> <br></br> Offrez à votre famille des moments précieux en explorant de nouvelles destinations, en toute sérénité.
                            </li><br></br>
                            <li>
                              •	<b>Projets à Deux : </b> <br></br> Que ce soit pour un mariage, l'achat d'une nouvelle maison ou d'une voiture, La French Tontine peut vous aider à réaliser vos projets à deux.
                            </li>
                          </ul>

                          Avec notre plateforme, vous pouvez planifier votre épargne de manière intelligente, en fonction de vos besoins et de vos priorités. Profitez de l'opportunité de collaborer avec d'autres membres de confiance pour atteindre vos objectifs plus rapidement que jamais.

                          <br></br><br></br>Ne laissez pas les contraintes financières freiner vos projets et vos rêves. Rejoignez La French Tontine aujourd'hui et transformez vos aspirations en réalité avec une communauté d'épargnants dévouée.

                          <br></br><br></br>La French Tontine vous aide à concrétiser vos projets, un pas à la fois. N'attendez plus pour réaliser ce qui compte vraiment pour vous !
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="card border-theme radius-0">
                    <div
                      className="card-header"
                      onClick={() => toggleMenu(4)}
                    >
                      <a
                        href="javascript"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className=""
                      >
                        <b>Êtes-vous étudiant ?</b>
                        <i className="fa fa-angle-down"></i>
                      </a>
                    </div>
                    <Collapse isOpen={collapse === 4 ? isOpen : false}>
                      <div>
                        <div className="card-body">
                          Chers étudiants,

                          <br></br><br></br>La French Tontine est ravie de vous présenter une solution innovante pour répondre à vos besoins financiers tout au long de votre vie étudiante. Nous comprenons les défis auxquels vous êtes confrontés en jonglant avec vos études, votre logement, votre permis de conduire, et l'envie de vivre des expériences enrichissantes. C'est pourquoi nous avons créé La French Tontine pour vous aider à réaliser vos rêves et à surmonter les obstacles financiers.
                        
                          <br></br> <h3 className="custom-color">Problèmes rencontrés : </h3>
                          <ul>
                            <li>
                              •	<b>Financement des Études : </b> <br></br> Les coûts des études supérieures peuvent être élevés, et trouver des fonds pour les frais de scolarité, les manuels et les dépenses connexes peut être un véritable défi.
                            </li><br></br>
                            <li>
                              •	<b>Location de Logement : </b> <br></br> Trouver un logement abordable et financer le loyer peut être difficile, surtout dans les zones urbaines.
                            </li><br></br>
                            <li>
                              •	<b>Obtention du Permis de Conduire : </b> <br></br> Le coût du permis de conduire et de l'assurance peut être prohibitif, mais la mobilité est souvent essentielle.
                            </li><br></br>
                            <li>
                              •	<b>Vacances et Voyages : </b> <br></br> Les études peuvent être stressantes, et les étudiants méritent également de se détendre et de découvrir de nouveaux horizons, mais les voyages nécessitent un budget.
                            </li>
                          </ul>

                          <h3 className="custom-color">Solutions offertes par La French Tontine : </h3>
                          <ul>
                            <li>
                              •	<b>Financement Collaboratif : </b> <br></br> Rejoignez notre communauté d'épargnants pour contribuer collectivement à votre éducation. Profitez de cotisations régulières pour alléger le fardeau financier des études.
                            </li><br></br>
                            <li>
                              •	<b>Aide à la Location : </b> <br></br> La French Tontine peut vous aider à financer la location de votre logement étudiant, en garantissant un accès à des fonds rapidement et sans soucis.
                            </li><br></br>
                            <li>
                              •	<b>Permis de Conduire Abordable : </b> <br></br> Économisez pour l'obtention de votre permis de conduire et l'assurance, ou partagez les coûts avec d'autres membres de La French Tontine.
                            </li><br></br>
                            <li>
                              •	<b>Vacances et Voyages Inoubliables : </b> <br></br> Planifiez vos escapades sans stress financier en utilisant La French Tontine pour financer vos aventures.
                            </li>
                          </ul>

                          Avec La French Tontine, vous pouvez créer un plan d'épargne intelligent et flexible pour atteindre vos objectifs étudiants, sans sacrifier vos rêves. Notre plateforme vous offre la possibilité de collaborer avec d'autres membres de confiance pour maximiser votre épargne.
                        
                          <br></br><br></br>La French Tontine est là pour vous aider à profiter pleinement de votre vie étudiante, en minimisant les obstacles financiers. Ne laissez pas l'argent vous empêcher de vivre pleinement cette période passionnante. Rejoignez-nous dès aujourd'hui et transformez vos aspirations en réalité !

                          <br></br><br></br>La French Tontine : votre partenaire pour un avenir étudiant réussi et sans soucis.
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Profil;
