import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


// Slick slider Option for Testimonial
const options = {
  arrows: false,
  infinite: true,
  dots: false,
  appendDots: (dots) => (
    <span
      className=".blog-carousel.owl-theme .owl-dots .owl-dot.active span"
      //   style={{ paddingRight: "180px" }}
    >
      {dots}
    </span>
  ),
  // marginRight: 30,
  speed: 300,
  autoplay: true, // Enable autoplay
  autoplaySpeed: 4000, // Set autoplay speed in milliseconds (e.g., 3000ms = 3 seconds)
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        margin: 0,
      },
    },

    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

let data = [
  {
    // name: "Je tontine, elle tontine, nous tontinons...et vous ?",
    name: "Tontine, confiance et solidarité",
    // description: "Ensemble, réinventons l'épargne et le prêt entre amis.",
    description: "Tontiner, c´est mettre en commun les efforts de chacun."
  },
  {
    name: "J'ai bâti mon immeuble",
    description: "Elle vous aide à mieux gérer vos finances.",
  },
  {
    name: "J'ai facilement géré la rentrée scolaire",
    description: "Elle vous aide à mieux gérer l'épargne/crédit.",
  },
  {
    name: "Nous avons réalisé notre projet",
    description: "Elle facilite la gestion de différentes activités.",
  },
  {
    name: "Mon autonomie financière et solidaire",
    description: "Avec mes amis on voudrait se soutenir les uns les autres. Certains ont besoin d'argent tout de suite et d'autres aimeraient réussir à mettre de l'argent de côté...",
  },
  
];

// Dynamic Testimonial Data Loop
let DataList = data.map((val, i) => {
  return (
    <div key={i}>
      <div>
        <h3>{val.name}</h3>
        <p>{val.description}</p>
      </div>
    </div>
  );
});

const MySlider = () => {
  return <Slider {...options}>{DataList}</Slider>;
};

export default MySlider;
