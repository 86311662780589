import React, { useEffect, useState } from "react";
import Icon from "../Services/Icon";

const Feature = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(/assets/images/feature-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(/assets/images/feature-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(/assets/images/color/color-2/feature-bg1.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(/assets/images/color/color-3/feature-bg1.png)`,
      });
  }, [color]);

  return (
    <section id="feature" className="feature" style={bgImg}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img src={`/assets/images/feature2.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="feature-phone">
            <img src={`/assets/images/222.png`} className="img-fluid" alt="" />
          </div>
          <div className="offset-lg-5 col-lg-7">
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title">
                  Comment fonctionne
                  
                  <br /> la <span className="main-title"> FRENCH TONTINE ?</span>
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <Icon
                        fontSize="30px"
                        icon="tabler:bell-ringing"
                        color="#7239E4"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Notifications</h3>
                    </div>
                    <div>
                      <p>
                        Soyez immédiatement informé de toute nouvelle actualité
                        au sein de votre tontine rotative en ligne.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      {/* <img src={`/assets/images/icon/2.png`} alt="icon" /> */}
                      <Icon
                        fontSize="30px"
                        icon="tabler:shield-dollar"
                        color="#7239E4"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Transferts sécurisés</h3>
                    </div>
                    <div>
                      <p>
                        Vous pouvez utiliser des virements ou prélèvement
                        bancaires pour faire vos cotisations. Toutes les
                        transactions sont sécurisées et la gestion des wallet
                        est opérée par notre partenaire qui a la qualité
                        d’établissement de paiement agréé ACPR.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <Icon
                        fontSize="30px"
                        icon="tabler:calendar-event"
                        color="#7239E4"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Agenda</h3>
                    </div>
                    <div>
                      <p>
                        Maitrisez toutes vos échéances, ainsi que celles des
                        autres participants, et ne vous laissez plus surprendre
                        par le temps.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <Icon
                        fontSize="30px"
                        icon="tabler:cloud-dollar"
                        color="#7239E4"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Mode de levée</h3>
                    </div>
                    <div>
                      <p>
                        Vous avez la possibilité de choisir entre plusieurs
                        modes de récupération de vos gains : tirage au sort,
                        choix fait à l’avance, enchères, ou vote.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <Icon
                        fontSize="30px"
                        icon="tabler:brand-wechat"
                        color="#7239E4"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Messagerie interne</h3>
                    </div>
                    <div>
                      <p>
                        Bénéficiez d’un espace de messagerie pour discuter avec
                        tous les membres de votre tontine rotative, ou un seul
                        d’entre eux.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 sm-m-top transparent-div">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      {/* <img src={`/assets/images/icon/6.png`} alt="icon" /> */}

                      <Icon
                        fontSize="30px"
                        icon="tabler:clock-dollar"
                        color="#7239E4"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Suivi en temps réel</h3>
                    </div>
                    <div>
                      <p>
                        Gérez facilement vos tontines rotatives. Vous êtes au
                        courant en un clin d’oeil du récapitulatif de vos
                        tontines en ligne.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
