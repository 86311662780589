import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // NavLink,
} from "reactstrap";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";

const reactRouterDom = require("react-router-dom");
const RLink = reactRouterDom.Link;

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inAnotherPage, setInAnotherPage] = useState(false);
  const [faq, setFaq] = useState(false);
  const [aboutUs, setAboutUs] = useState(false);
  const [targetProfiles, setTargetProfiles] = useState(false);
  
  const location = useLocation();

  useEffect(() => {
    // Perform actions when the route changes
    const path = location.pathname.toLowerCase();

    setInAnotherPage(path.includes("faq") || path.includes("about-us") || path.includes("target-profiles"));
    setFaq(path.includes("faq"));
    setAboutUs(path.includes("about-us"));
    setTargetProfiles(path.includes("target-profiles"));
    // Additional logic...
  }, [location]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const HandleScroll = () => {
    if (window.scrollY >= 60) {
      document.getElementById("Navbar")?.classList.add("darkHeader");
    } else document.getElementById("Navbar")?.classList.remove("darkHeader");
  };

  window.addEventListener("scroll", HandleScroll);
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Navbar
      id="Navbar"
      className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
    >
      <div id="navbar-main" className="container">
        <RLink to="/home" onClick={toggle}>
          <NavbarBrand className="navbar-brand">
            <img
              style={{ width: "85px" }}
              src="/assets/images/french-tontine.png"
              alt="logo"
            />
            <img
              style={{ width: "118px" }}
              src="/assets/images/french-tontine-name.png"
              alt="logo"
            />
          </NavbarBrand>
        </RLink>
        <NavbarToggler className="navbar-toggler" onClick={toggle} />
        <Collapse
          id="navbarSupportedContent"
          className="default-nav"
          isOpen={isOpen}
          navbar
        >
          <Nav className="ml-auto" navbar>
            <NavItem>
              <RLink className="nav-link" to="/" onClick={toggle}>
                accueil
              </RLink>
            </NavItem>
            <NavItem>
              {!inAnotherPage && (
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  à propos
                </Link>
              )}

              {inAnotherPage && (
                <RLink className={`nav-link ${aboutUs ? " active" : ""}`} to="/about-us" onClick={toggle}>
                  à propos
                </RLink>
              )}
            </NavItem>
            
            <NavItem>
              <RLink
                className={`nav-link ${targetProfiles ? " active" : ""}`}
                to="/target-profiles"
                onClick={toggle}
              >
                Profils ciblés
              </RLink>
            </NavItem>
            
            <NavItem>
              {!inAnotherPage && (
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="feature"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  Comment ça Marche
                </Link>
              )}

              {inAnotherPage && (
                <RLink className="nav-link" to="/home" onClick={toggle}>
                  Comment ça Marche
                </RLink>
              )}
            </NavItem>
            {/* <NavItem>
              {!inAnotherPage && (
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="screenshot"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  capture d'écran
                </Link>
              )}

              {inAnotherPage && (
                <RLink className="nav-link" to="/home" onClick={toggle}>
                  capture d'écran
                </RLink>
              )}
            </NavItem> */}
            <NavItem>
              {!inAnotherPage && (
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="team"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  équipe
                </Link>
              )}

              {inAnotherPage && (
                <RLink className="nav-link" to="/home" onClick={toggle}>
                  équipe
                </RLink>
              )}
            </NavItem>
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {" "}
                blog
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <RLink className="nav-link" to="/blog-list" onClick={toggle}>
                    Blog list
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink
                    className="nav-link"
                    to="/blog-details"
                    onClick={toggle}
                  >
                    Blog details
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink
                    className="nav-link"
                    to="/blog-leftside"
                    onClick={toggle}
                  >
                    Blog leftside
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink
                    className="nav-link"
                    to="/blog-rightside"
                    onClick={toggle}
                  >
                    Blog rightside
                  </RLink>
                </DropdownItem>
                <DropdownItem className="nav-item">
                  <RLink
                    className="nav-link"
                    to="/blog-left-sidebar"
                    onClick={toggle}
                  >
                    detail left side
                  </RLink>
                </DropdownItem>
                <DropdownItem className="nav-item">
                  <RLink
                    className="nav-link"
                    to="/blog-right-sidebar"
                    onClick={toggle}
                  >
                    detail right side
                  </RLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {/* <NavItem>
              {!inAnotherPage && (
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="price"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  prix
                </Link>
              )}

              {inAnotherPage && (
                <RLink className="nav-link" to="/home" onClick={toggle}>
                  prix
                </RLink>
              )}
            </NavItem> */}
            <NavItem>
              {!inAnotherPage && (
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  s'inscrire
                </Link>
              )}

              {inAnotherPage && (
                <RLink className="nav-link" to="/home" onClick={toggle}>
                  s'inscrire
                </RLink>
              )}
            </NavItem>
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                other page
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <RLink className="nav-link" to="/sign-in" onClick={toggle}>
                    sign in
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink className="nav-link" to="/sign-up" onClick={toggle}>
                    sign up
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink
                    className="nav-link"
                    to="/forget-password"
                    onClick={toggle}
                  >
                    forget password
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink className="nav-link" to="/thank-you" onClick={toggle}>
                    thank you
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink className="nav-link" to="/review" onClick={toggle}>
                    review
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink className="nav-link" to="/404" onClick={toggle}>
                    404
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink className="nav-link" to="/FAQ" onClick={toggle}>
                    FAQ
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink className="nav-link" to="/download" onClick={toggle}>
                    download
                  </RLink>
                </DropdownItem>
                <DropdownItem>
                  <RLink
                    className="nav-link"
                    to="/coming-soon"
                    onClick={toggle}
                  >
                    coming soon
                  </RLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}

            <NavItem>
              <RLink
                className={`nav-link ${faq ? " active" : ""}`}
                to="/FAQ"
                onClick={toggle}
              >
                FAQ
              </RLink>
            </NavItem>
            
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

export default Menu;
