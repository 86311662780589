import "./App.css";
import BlogDetails from "./blog/BlogDetails";
import BlogLeftSide from "./blog/BlogLeftSide";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogList from "./blog/BlogList";
import BlogRightSide from "./blog/BlogRightSide";
import BlogRightSidebar from "./blog/BlogRightSidebar";
// import DemoApp from "./demo-page/index";
import PageNotFound from "./Pages/404";
import ComingSoon from "./Pages/ComingSoon";
import Download from "./Pages/Download";
import FAQ from "./Pages/FAQ";
import ForgetPwd from "./Pages/ForgetPwd";
import Review from "./Pages/Review";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import ThankYou from "./Pages/ThankYou";
import React,{ useEffect} from "react";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import TargetProfiles from "./Pages/TargetProfiles";

import Home from "./Home";
// import ColorPicker from "./Services/ColorPicker";

function App() {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const customizeColor = (color) => {
    document.getElementById("color").innerHTML =
      `<link href="assets/css/color/${color}.css"  rel="stylesheet" />`;
      localStorage.setItem("color",color);
  };

  customizeColor('color-1')

  return (
    <div className="App">
      {/* <ColorPicker /> */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<HomeOne />} />
        <Route path="/home-one" element={<HomeOne />} />
        <Route path="/home-two" element={<HomeTwo />} />
        <Route path="/home-three" element={<HomeThree />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/blog-list" element={<BlogList />} />
        <Route path="/blog-left-sidebar" element={<BlogLeftSidebar />} />
        <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
        <Route path="/blog-leftside" element={<BlogLeftSide />} />
        <Route path="/blog-rightside" element={<BlogRightSide />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forget-password" element={<ForgetPwd />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/review" element={<Review />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/target-profiles" element={<TargetProfiles />} />
        <Route path="/download" element={<Download />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
