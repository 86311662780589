import React, { useEffect, useState } from "react";

const AboutOurVision = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  const [teamBg, setTeamBg]= useState({
    backgroundImage:`url(/assets/images/team-bg.png)`
  });
  const color= localStorage.getItem("color");

  useEffect(()=>{
  
    if(color === "color-1")
    {
     setTeamBg( {
      backgroundImage:`url(/assets/images/team-bg.png)`
    });
  }
    else if(color === "color-2")
    {
    setTeamBg({ backgroundImage:`url(/assets/images/color/color-2/team-bg.png)`
	})
}
    else 
    {
    setTeamBg({backgroundImage:`url(/assets/images/color/color-3/team-bg.png)`
	})
}
  },[color]);

  return (
    <section id="team"  className="team" style={teamBg}>
      <div className="team-decor">
        <div className="team-circle1">
          <img src={`/assets/images/team1.png`} alt="" />
        </div>
        <div className="team-circle2">
          <img src={`/assets/images/team3.png`} alt="" />
        </div>
        <div className="team-circle3">
          <img src={`/assets/images/team.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-sm-12">
            <h2 className="title">
              notre <span>vision</span>
            </h2>
          </div>
          <div className="col-md-8 transparent-div">
            <div>
              <p className="caption-about" style={{ color: '#212529' }}>
                Notre ambition est de réunir une multitude de cultures et d'horizons via une plateforme 
                numérique novatrice, remodelant la tontine avec technologie pour plus de transparence, 
                de sécurité et d'équité, tout en préservant les valeurs de coopération. Cette alliance 
                entre tradition et modernité vise à catalyser une transformation positive de la vie financière 
                de nos membres, créant un tremplin économique pour des opportunités significatives.
              </p>
            </div>

            <br/>
            <h2 className="title">
              notre <span>mission</span>
            </h2>
            <div>
              <p className="caption-about" style={{ color: '#212529' }}>
                <ul>
                  <li> <b>•</b> Réunir les particuliers et les entrepreneurs grâce à la tontine, et leur permettre de réaliser leurs projets. </li>
                  <li> <b>•</b> Une tontine digitale dans le but de regrouper particuliers et professionnels et leur permettre de mettre en marche des projets innovants. </li>
                  <li> <b>•</b> Pousser à l'innovation tout en rassemblant particuliers et entrepreneurs autour d'un système dé'pargne collective. </li>
                </ul>
              </p>
            </div>

            <div className="top-margin ml-2 mt-5">
                                <a
                                  href={`/home#contact `}
                                  className="btn btn-custom theme-color"
                                  role="button"
                                >
                                    Rejoignez la famille
                                </a>
                            </div>
          </div>
          <div className="col-md-4 d-medium-none">
            <div className="about-phone">
              <img
                src={`/assets/images/icon/vision.png`}
                className="img-fluid"
                alt="aboutus"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutOurVision;
