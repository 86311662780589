import ReCAPTCHA from "react-google-recaptcha";
import React, { useEffect, useState } from "react";

// import RegistraTionRequestService from "../logic/services/RegistrationRequestService.ts";

// ** Third Party Imports
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

import Select from "react-select";
import Modal from './Dialog'

// ** Axios
import axios from "../logic/configs/axios-config";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./phoneNumberInput.css";

const schema = yup.object().shape({
  firstname: yup.string().required("Le champ Prénom est obligatoire"),
  lastname: yup.string().required("Le champ Nom de famille est obligatoire"),
  email: yup
    .string()
    .email("L'email doit être valide")
    .required("L'email est un champ obligatoire"),
  phoneNumber: yup
    .string()
    .required('Le champ du "numéro de téléphone" est obligatoire'),
  residenceCity: yup
    .string()
    .required('Le champ "ville de résidence" est obligatoire'),
  residenceCountry: yup
    .string()
    .required('Le champ "pays de résidence" est obligatoire'),
  functionT: yup.string().required('Le champ "function" est obligatoire'),
  reCaptchaToken: yup.string().required('Le champ "reCAPTCHA" est obligatoire'),
  iHaveAlreadyParticipatedInATontine: yup
    .string()
    .oneOf(['yes', 'no'], 'Veuillez sélectionner une option')
    .required("Ce champ est obligatoire"),
  minRevenues: yup.string().required("Ce champ est obligatoire"),
  maxRevenues: yup.string().required("Ce champ est obligatoire"),
  maximumTontineAmount: yup.string().required("Ce champ est obligatoire"),
  typeOfProjectFinanced: yup.string().required("Ce champ est obligatoire"),
  // waitingForHope: yup.string().required("Ce champ est obligatoire"),
});

const defaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  phoneNumber: "",
  phoneNumber2: "",
  residenceCity: "",
  residenceCountry: "",
  functionT: "",
  reCaptchaToken: "",
  additionalInformation: "",
  iHaveAlreadyParticipatedInATontine: "",
  minRevenues: "",
  maxRevenues: "",
  maximumTontineAmount: "",
  typeOfProjectFinanced: "",
  // waitingForHope: null
};

const inputError = { fontSize: "10px", color: "red" }; // #EB6302
const inputBorderError = { border: "1px solid red" };

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const recaptchaRef = React.createRef();

  const borderStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      paddingLeft: "18px",
    }),
    // option: (styles, { isFocused }) => ({
    //   ...styles,
    //   backgroundColor: isFocused ? '#7239E4' : '', // Change the color of the dropdown items
    //   color: isFocused ? 'white' : '', // Change the color of the dropdown items
    // }),
  };

  const captchaOnChange = (reCaptchaToken) => {
    console.log("Captcha value:", reCaptchaToken);
    setValue("reCaptchaToken", reCaptchaToken);
  };

  const onSubmit = (data) => {
    axios
      .post(`/inscription/add`, {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phoneNumber: data.phoneNumber,
        phoneNumber2: data.phoneNumber2,
        residenceCity: data.residenceCity,
        residenceCountry: data.residenceCountry,
        function: data.functionT,


        iHaveAlreadyParticipatedInATontine: data.iHaveAlreadyParticipatedInATontine,
        minRevenues: data.minRevenues,
        maxRevenues: data.maxRevenues,
        maximumTontineAmount: data.maximumTontineAmount,
        typeOfProjectFinanced: data.typeOfProjectFinanced,

        
        // reCaptchaToken: data.reCaptchaToken,
        additionalInformation: data.additionalInformation
      }, {
        headers: {
          // ...getHeadersInformation()
          "email-verification-url":
            "https://french-tontine-app.his-tech.tech/email-verification/",
        },
      })
      .then((response) => {
        setIsOpen(true)
        reset();
      })
      .catch((error) => {
        console.log(" error.response.data ", error.response.data);

        let description = null

        try {
          description = error.response.data.status.description
        } catch (error2) {
          description = null
          console.log("error2 : ", error2)
        }

        if (description !== null && description.toLowerCase().includes("email")) {
          setError("email", {
            type: "manual",
            message: "Cet e-mail existe déjà, veuillez en utiliser un autre",
          });
        } else {
          setError("lastName", {
            type: "manual",
            message: "Une erreur s'est produite, contactez le super-admin pour la corriger.",
          });
        }
      });
  };

  // ** Hooks
  const {
    reset,
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  // Old source code
  useEffect(() => {
    axios
      .get(`/country/list`)
      .then((response) => {
        console.log(" /country/list` response.data ", response.data.data)
        setCountries(response.data.data.map(item => { return { value: item._id, label: item.name }}))
      })
      .catch((error) => {
        console.log(" error ", error);
      });


    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(/assets/images/Contact.png)`,
  });

  const color = localStorage.getItem("color");

  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(/assets/images/Contact.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(/assets/images/color/color-2/contact.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(/assets/images/color/color-3/contact.png)`,
      });
  }, [color]);

  const getInputStyle = (rep) => {
    return rep
      ? { ...inputBorderError, borderRadius: "8px" }
      : { borderRadius: "8px" };
  };

  return (
    <section id="contact" className="contact" style={bgImg}>
      <main>
        {isOpen && <Modal setIsOpen={setIsOpen} />}
      </main>
      <div className="contact-decor">
        <div className="contact-circle1">
          <img src="assets/images/main-banner12.png" alt="" />
        </div>
        <div className="contact-circle2">
          <img src="assets/images/main-banner1.png" alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <div className="contact-us">
              <div className="w-100">
                <h2 className="title">
                  <span>Demande </span>d’inscription
                </h2>
                <form onSubmit={handleSubmit(onSubmit)} className="theme-form">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <Controller
                          name="firstname"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <input
                              className="form-control"
                              style={getInputStyle(Boolean(errors.firstname))}
                              placeholder="Prénom *"
                              {...field}
                            />
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.firstname?.message}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <Controller
                          name="lastname"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <input
                              className="form-control"
                              style={getInputStyle(Boolean(errors.lastname))}
                              placeholder="Nom *"
                              {...field}
                            />
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.lastname?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <Controller
                          name="email"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <input
                              className="form-control"
                              style={getInputStyle(Boolean(errors.email))}
                              placeholder="Email *"
                              {...field}
                            />
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.email?.message}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <Controller
                          name="functionT"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <input
                              className="form-control"
                              style={getInputStyle(Boolean(errors.functionT))}
                              placeholder="Votre fonction *"
                              {...field}
                            />
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.functionT?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <Controller
                          name="phoneNumber"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <div
                              style={getInputStyle(Boolean(errors.phoneNumber))}
                              className="input-phone-number-simple  form-control input-phone-number"
                            >
                              <div>
                                <PhoneInput
                                  placeholder="Numéro de téléphone *"
                                  value={value}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.phoneNumber?.message}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <Controller
                          name="phoneNumber2"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <div
                              style={getInputStyle(false)}
                              className="input-phone-number-simple  form-control input-phone-number"
                            >
                              <div>
                                <PhoneInput
                                  placeholder="Numéro de téléphone"
                                  value={value}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <Controller
                          name="residenceCountry"
                          control={control}
                          rules={{ required: true }}
                          // render={({ field }) => (
                          //   <input
                          //     className="form-control"
                          //     style={getInputStyle(
                          //       Boolean(errors.residenceCountry)
                          //     )}
                          //     placeholder="Pays de résidence"
                          //     {...field}
                          //   />

                          // )}

                          render={({ field: { value, onChange } }) => (
                            <div
                              style={{
                                ...getInputStyle(
                                  Boolean(errors.residenceCountry)
                                ),
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                              className="form-control"
                            >
                              <div>
                                <Select
                                  placeholder="Pays de résidence *"
                                  value={countries.find(element => element.value === value) === undefined ? '' : countries.find(element => element.value === value) }
                                  onChange={(e) => {
                                    console.log(" e ", e);
                                    onChange(e.value);
                                  }}
                                  options={countries}
                                  styles={borderStyle}
                                  theme={(theme) => ({
                                    ...theme,
                                    // borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "rgba(24, 231, 211, 0.2)",
                                      primary: "#7239E4",
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.residenceCountry?.message}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <Controller
                          name="residenceCity"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <input
                              className="form-control"
                              style={getInputStyle(
                                Boolean(errors.residenceCity)
                              )}
                              placeholder="Ville de résidence *"
                              {...field}
                            />
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.residenceCity?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <Controller
                          name="iHaveAlreadyParticipatedInATontine"
                          control={control}
                          rules={{ required: true }}
                          // render={({ field }) => (
                          //   <input
                          //     className="form-control"
                          //     style={getInputStyle(
                          //       Boolean(errors.residenceCountry)
                          //     )}
                          //     placeholder="Pays de résidence"
                          //     {...field}
                          //   />

                          // )}

                          render={({ field }) => (
                            <div className="form-control" style={{ backgroundColor: "white", borderRadius: "8px", marginLeft: "0px", marginRight: "0px", paddingTop: "8px" }}>
                              <div className="row">
                                <div>
                                  Avez vous dejá participé à une tontine ?
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6" style={{ paddingLeft: "40px" }}>
                                  <label className="radio-inline" onClick={() => {
                                    const { onChange } = field;
                                    onChange("yes");
                                  }}>
                                    <input
                                      type="radio"
                                      value="yes"
                                      {...field}
                                      className="form-check-input custom-radio-input-yes"
                                    />
                                    <span className="custom-radio-label">Oui</span>
                                  </label>
                                </div>

                                <div className="col-6">
                                  <label className="radio-inline" onClick={() => {
                                    const { onChange } = field;
                                    onChange("no");
                                  }}>
                                    <input
                                      type="radio"
                                      value="no"
                                      {...field}
                                      className="form-check-input custom-radio-input-no"
                                    />
                                    <span className="custom-radio-label">Non</span>
                                  </label>
                                </div>
                              </div>
                            </div>

                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.iHaveAlreadyParticipatedInATontine?.message}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                      <div className="row" style={{ marginBottom: "5px" }} >
                            <div>
                              <b>Indiquez la fourchette de vos revenus</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <Controller
                                name="minRevenues"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <input
                                    type="number"
                                    className="form-control"
                                    style={getInputStyle(Boolean(errors.minRevenues))}
                                    placeholder="Min *"
                                    {...field}
                                  />
                                )}
                              />
                              <div className="pl-3" style={{ ...inputError }}>
                                {errors.minRevenues?.message}
                              </div>
                            </div>

                            <div className="col-6">
                              <Controller
                                name="maxRevenues"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <input
                                    type="number"
                                    className="form-control"
                                    style={getInputStyle(Boolean(errors.maxRevenues))}
                                    placeholder="Max *"
                                    {...field}
                                  />
                                )}
                              />
                              <div className="pl-3" style={{ ...inputError }}>
                                {errors.maxRevenues?.message}
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <div>
                          <b>A quel montant maximum êtes vous prêt à tontiner ?</b>
                        </div>
                        <Controller
                          name="maximumTontineAmount"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <input
                              type="number"
                              className="form-control"
                              style={getInputStyle(Boolean(errors.maximumTontineAmount))}
                              placeholder="A quel montant maximum êtes vous prêt à tontiner ? *"
                              {...field}
                            />
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.maximumTontineAmount?.message}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div>
                          <b>Quel type de projet avez-vous financer avec votre tontine ?</b>
                        </div>
                        <Controller
                          name="typeOfProjectFinanced"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <input
                              className="form-control"
                              style={getInputStyle(Boolean(errors.typeOfProjectFinanced))}
                              placeholder="Quel type de projet avez-vous financer avec votre tontine ? *"
                              {...field}
                            />
                          )}
                        />
                        <div className="pl-3" style={{ ...inputError }}>
                          {errors.typeOfProjectFinanced?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <Controller
                      name="additionalInformation"
                      control={control}
                      render={({ field }) => (
                        <textarea
                          style={getInputStyle(false)}
                          className="form-control"
                          rows="4"
                          placeholder="Informations complémentaires *"
                          {...field}
                        />
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <ReCAPTCHA
                      style={getInputStyle(Boolean(errors.reCaptchaToken))}
                      ref={recaptchaRef}
                      sitekey="6LcxOOAmAAAAAPKdlnlRPFYEW2YwRQyjtIBJCNXw"
                      onChange={captchaOnChange}
                    />
                    <div className="pl-3" style={{ ...inputError }}>
                      {errors.reCaptchaToken?.message}
                    </div>
                  </div>
                  <div className="form-button">
                    <button
                      type="submit"
                      className="btn btn-custom theme-color"
                    >
                      envoyer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-medium-none-lg">
            <div className="contact-right">
              <img
                src="assets/images/Contact-info.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
