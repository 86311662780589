import React, { useEffect, useState } from "react";
import Slider from "react-slick";

const Partners = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
//   const [bgImg, setBgImg]= useState({
//     backgroundImage:`url(/assets/images/team-img-bg.png)`
//   });

  const [teamBg, setTeamBg]= useState({
    backgroundImage:`url(/assets/images/team-bg.png)`
  });
//   const color= localStorage.getItem("color");

//   useEffect(()=>{
  
// //     if(color === "color-1")
// //     {
// //       setBgImg( {
// //         backgroundImage:`url(/assets/images/team-img-bg.png)`
// //       });
// //      setTeamBg( {
// //       backgroundImage:`url(/assets/images/team-bg.png)`
// //     });
// //   }
// //     else if(color === "color-2")
// //     {
// //       setBgImg({ backgroundImage:`url(/assets/images/color/color-2/team-img-bg.png)`
// //     })
// //     setTeamBg({ backgroundImage:`url(/assets/images/color/color-2/team-bg.png)`
// // 	})
// // }
// //     else 
// //     {
// //     setBgImg({backgroundImage:`url(/assets/images/color/color-3/team-img-bg.png)`
// // 	})
// //     setTeamBg({backgroundImage:`url(/assets/images/color/color-3/team-bg.png)`
// // 	})
// // }
//   },[color]);

  // Slick slider Option for Team Members
  const options = {
    arrows: true,
    accessibility: false,
    infinite: true,
    speed: 300,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 4000, 
    dots: false,
    appendDots: (dots) => (
      <span className=".blog-carousel">
        {dots}
      </span>
    ),
    marginRight: 30,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Dynamic Team Members Easy to Update
  let data = [
    {
      name: "mark jkcno",
      designation: "designer",
      photo:`/assets/images/partenaires/partenaire1.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/partenaire2.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },////////////
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/airdie-occitanie.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/Axents.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/credit-agricole.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/La-melee-numérique.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/le-medef.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/Departement-de-l-Herault.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/Credit-Agricole-Fondation.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    {
      name: "john doe",
      designation: "devloper",
      photo: `/assets/images/partenaires/Suez.png`,
      facebook: "#",
      google: "#",
      twitter: "#",
      instagram: "#",
      rss: "#",
    },
    
  ];


  // Dynamic Team Members Data Loop
  let DataList = data.map((val, i) => {
    return (
      <div className="team-item d-flex" style={{ marginRight: "30px" }} key={i}>
        <img src={val.photo} alt="" className="img-fluid" style={{ height: "80px", width: "auto" }}/>
      </div>
    );
  });

  return (
    <section id="team"  className="team" style={teamBg}>
      <div className="container">
        <div className="row ">
        <div className="col-sm-12 mb-3 mb-lg-5">
            <h2 className="title">
              Ceux qui nous <span>soutiennent</span>
            </h2>
          </div>
          <div className="col-sm-12">
            <div>
              <Slider
                {...options}
                className="team-carousel rounded-dots slick-margin"
              >
                {DataList}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
