import React from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";

const Modal = ({ setIsOpen }) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className="french-home-decor">
            <div className="french-home-circle1">
              <img src="/assets/images/main-banner3.png" alt="" />
            </div>
            <div className="french-home-circle2">
              <img src="/assets/images/main-banner12.png" alt="" />
            </div>
          </div>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>Validation de mail</h5>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className={styles.modalContent}>
            Afin de procéder à la validation de votre mail, un lien a été envoyé
            dans votre boite mail. Veuillez bien cliquer sur ce lien afin de
            valider votre mail.
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button
                className="btn btn-custom theme-color"
                onClick={() => setIsOpen(false)}
              >
                D'accord
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
