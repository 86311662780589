import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import Menu from "../components/Navbar";

const faqArray = [
  {
    title: "Qu'est ce qu'une tontine digitale ?",
    description: `
    Une tontine, c’est un groupe de personnes qui décide de cotiser une certaine somme d’argent périodiquement pour que chaque participant récupère le montant de la cagnotte chacun à son tour.

    <br><br>Les tontines digitales sont des tontines modernes qui utilisent la technologie pour offrir encore plus de flexibilité et de commodité aux utilisateurs. Elles permettent aux membres de participer en ligne et à distance via des plateformes numériques et des applications web, plutôt que de se réunir en personne pour mettre de l’argent dans une caisse commune. Donc les tontines digitales fonctionnent de la même manière que les tontines traditionnelles, mais avec l’ajout de la technologie.

    <br><br>Les tontines digitales peuvent être utilisées pour économiser de l’argent, financer des projets ou collecter des fonds pour des œuvres caritatives….

    <br><br>Les tontines digitales peuvent également être personnalisées en fonction des besoins des utilisateurs, avec des options pour la fréquence et le montant des paiements.Les tontines digitales offrent encore plus de flexibilité et de commodité pour les utilisateurs modernes car elles peuvent offrir des avantages tels qu’un accès à la tontine plus facile et plus pratique
    `,
  },
  {
    title: "Peut-on s'inscrire en groupe ?",
    description: "Oui il est possible de s´inscrire en groupe. Vous pouvez envoyer toutes vos invitations en une seule fois, ou bien envoyer de nouvelles invitations tant que la tontine n’a pas démarré. Il suffit pour cela d’aller dans le détail de votre tontine et de cliquer sur le bouton « J’invite des participants ».",
  },
  {
    title: "Combien de temps dure la tontine ?",
    description: `
    La durée d’une tontine découle du paramétrage de la tontine lors de sa création. En effet, s’il y a 3 participants qui cotisent chaque mois, alors la tontine dure 3 mois. Chaque participant récupère ainsi une fois le montant de la tontine. C’est donc vous qui déterminez indirectement cette durée.

    <br><br>Vous avez la possibilité de tontiner pendant 3, 6 ou 9 mois
    `,
  },
  {
    title: "Comment notre argent est-il sécurissé ?",
    description: `
    Choisir La French Tontine c’est choisir une plateforme sécurisée qui permet de simplifier la gestion des tontines.

    <br><br>Le sérieux des participants est vérifié par l’organisateur avant le lancement d’une tontine. La plateforme la French Tontine est basée sur les dernières technologies pour sécuriser tous les transferts d’argent.

    <br><br>Les informations et les documents que vous fournissez sont validés par le Prestataire de Service de Paiement dans le respect du RGPD (Règlement Général de Protection des Données). Sans cette validation, un participant ne peut pas faire sa levée, c’est-à-dire qu’il ne peut pas sortir l’argent de la tontine.
    `,
  },
  {
    title: "Comment sera distribuée la tontine ?",
    description: `
    Vous récupérez l´argent de la tontine à tour de rôle. Lorsque c’est à votre tour, vous récupérez l’argent de la tontine simplement par un transfert de l’argent par virement de votre cagnotte directement vers votre compte bancaire.
    `,
  },
  {
    title: "Les étapes pour s'inscrire ?",
    description: `
    C’est très simple, il vous suffit de suivre le parcours client suivant :<br><br>
    1) Remplissez vos coordonnées, une adresse email valide est requise<br>
    2) Prenez un selfie de votre visage.<br>
    3) Téléchargez votre pièce d'identité valide (CNI, passeport ou permis de conduire).<br>
    4) Indiquer un numéro de compte bancaire (IBAN)<br><br>
    Vos éléments seront envoyés pour vérification (max 24 heures) et vous pourrez ensuite vous inscrire
    `,
  },
  {
    title: "Dois-je fournir une pièce d'identité pour m'inscrire ?",
    description: `
    Oui une pièce d´identité officielle (CNI, passeport ou permis de conduire) est requise pour valider une inscription
    `,
  },
  {
    title: "Comment se passe la récolte de l'argent de chaque participant ?",
    description: `
    Pour alimenter la tontine, c’est à dire verser de l’argent ou cotiser, vous avez deux possibilités : faire un paiement sécurisé par carte bancaire ou un virement depuis votre compte bancaire directement sur le portefeuille de la tontine en précisant bien la référence de transaction fournie par La French Tontine.
    `,
  },
  {
    title: "La plateforme est-elle accessible h24 ?",
    description: `
    La plateforme La French Tontine est accessible 24/7. Toute période de maintenace éventuelle de la plateforme sera notifiée aux utilisateurs à l´avance.
    `,
  },
  {
    title: "Peut-on vérifier si tous les participants ont bien reversé leur part ?",
    description: `
    La tontine est basée sur la confiance entre ses participants. Le créateur de la tontine invite des personnes en qui il a confiance et dont il pense qu’ils sauront honorer leurs engagements.

    <br><br>De plus, pour participer à une tontine, chaque participant doit fournir des documents prouvant son identité. La French Tontine s’appuie sur un partenaire certifié pour vérifier tous les documents fournis et s’assurer que chaque participant est fiable.
    
    <br><br>La French Tontine a aussi tout prévu pour aider les participants à ne pas oublier de verser leur part. En effet, la veille de chaque levée, chaque participant reçoit une notification pour l’inviter à verser sa cotisation. Si un participant n’envoie pas sa cotisation ou a du retard, il recevra une nouvelle notification.
    
    <br><br>De plus, lorsqu’un participant choisit de payer sa cotisation par carte bancaire, il a la possibilité de rendre le paiement récurrent et n’a ainsi plus besoin de s’occuper du paiement à chaque échéance : le paiement est ainsi automatisé.
    <br>Vous pouvez également visualiser sur votre compte La French Tontine que tous lesparticipants sont à jour de leurs versements
    
    
    `,
  },
  {
    title: "Faut-il être majeure pour s'inscrire ? Une autorisation parentale ?",
    description: `
    En principe il faut être majeur pour s´inscrire. Cependant un mineur émancipé / possédant une autorisation parentale peut soumettre sa demande d´inscpription 
    `,
  },
  {
    title: "Comment je suis protégé / assuré en tant que participant ?",
    description: `
    La French tontine, avec ses partenaires spécialisés, fait les vérifications nécessaires lors de l’inscription pour s’assurer de l’identité des participants et faciliter le bon déroulement de ma tontine.
    <br>Les participants sont choisis par vous et sont des personnes que vous connaissez. Le seul engagement que vous avez est l’engagement moral des personnes avec lesquelles vous choisissez de faire une tontine.
    <br>Une assurance est automatiquement souscrite auprés du prestataire de service de paiement lors de l´inscription pour garantir un défaut de non paiement ou de retard de paiement d’un des participants. 
    
    `,
  },
  {
    title: "Les participants peuvent-ils venir de plusieurs villes différentes ?.",
    description: `
    Oui il est possible de tontiner avec des participants venant de plusieurs villes différentes
    `,
  },
];

const FAQ = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [collapse, setCollapse] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const toggleMenu = (id) => {
    if (collapse === id) {
      setIsOpen(false);
      setCollapse(null);
    } else {
      setIsOpen(true);
      setCollapse(id);
    }
  };
  return (
    <div>
      {/* Navbar Component*/}
      <Menu />

      {/*blog Section start*/}
      <div className="page-margin">
        {/*breadcrumb start*/}
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span>FAQ</span>
                </h2>
              </div>
              <div className="col-md-6 col-sm-6 col-text-center">
                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                  <ol className="breadcrumb bg-transparent mb-0">
                    <li className="breadcrumb-item">
                      <a href={`/home`}>Accueil</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a
                        href="javascript"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        FAQ
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/*breadcrumb end*/}

        {/*sign in*/}
        <section>
          <div className="innerpage-decor">
            <div className="innerpage-circle1">
              <img src={`/assets/images/Testimonial2.png`} alt="" />
            </div>
            <div className="innerpage-circle2">
              <img src={`/assets/images/Testimonial1.png`} alt="" />
            </div>
          </div>
          {/*faq in*/}
          <div className="faq">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div id="accordion">
                    {faqArray.map((row, index) => (
                      <div key={index} className="card border-theme mb-3 radius-0">
                        <div
                          className="card-header"
                          onClick={() => toggleMenu(index + 4)}
                        >
                          <a
                            href="javascript"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            className=""
                          >
                            {row.title}
                            <i className="fa fa-angle-down"></i>
                          </a>
                        </div>

                        <Collapse isOpen={collapse === index + 4 ? isOpen : false}>
                          <div>
                            <div className="card-body">
                              <p>
                              {row.description.split('<br>').map((item, i) => <>
                                 { item } <br></br>
                                
                              </>)}
                              </p>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    ))}

                    {/* <div className="card border-theme mb-3 radius-0">
                      <div
                        className="card-header"
                        onClick={() => toggleMenu(2)}
                      >
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className=""
                        >
                          A quoi sert une tontine ?
                          <i className="fa fa-angle-down"></i>
                        </a>
                      </div>
                      <Collapse isOpen={collapse === 2 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            A quoi sert une tontine ? Une tontine a deux
                            fonctions principales : faire du crédit et épargner.
                            Un participant qui se trouve en début de liste va
                            recevoir une somme d’argent prêtée par les autres
                            participants et qu’il remboursera au fil des
                            échéances de la tontine. Il a ainsi du crédit et
                            pourra investir cet argent dans un projet qu’il aura
                            défini. Un participant qui se trouve en fin de liste
                            aura déjà cotisé la plupart des échéances. La
                            tontine aura donc servi à épargner cet argent qu’il
                            aurait peut-être déjà dépensé s’il ne l’avait pas
                            mis dans la tontine. Il pourra aussi utiliser cet
                            argent pour lancer un projet.
                          </div>
                        </div>
                      </Collapse>
                    </div>

                    <div className="card border-theme mb-3 radius-0">
                      <div
                        className="card-header"
                        onClick={() => toggleMenu(3)}
                      >
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className=""
                        >
                          Combien de participants puis-je inviter dans ma
                          tontine ?<i className="fa fa-angle-down"></i>
                        </a>
                      </div>
                      <Collapse isOpen={collapse === 3 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            Le nombre de participants d’une tontine est limité à
                            50. Vous pouvez envoyer toutes vos invitations en
                            une seule fois, ou bien envoyer de nouvelles
                            invitations tant que la tontine n’a pas démarré. Il
                            suffit pour cela d’aller dans le détail de votre
                            tontine et de cliquer sur le bouton « J’invite des
                            participants ».
                          </div>
                        </div>
                      </Collapse>
                    </div>

                    <div className="card border-theme radius-0">
                      <div
                        className="card-header"
                        onClick={() => toggleMenu(4)}
                      >
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className=""
                        >
                          Puis-je modifier une tontine ?
                          <i className="fa fa-angle-down"></i>
                        </a>
                      </div>
                      <Collapse isOpen={collapse === 4 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            <b>Cas d’une tontine non démarrée :</b>
                            <ul>
                              <li>
                                on ne peut pas modifier le montant de la
                                cotisation
                              </li>
                              <li>
                                on peut modifier les positions des participants
                                s’ils donnent leur accord
                              </li>
                              <li>
                                on peut démarrer une tontine même si le nombre
                                initial de participants n’est pas atteint et si
                                tous les co-tontiniers donnent leur accord. Si
                                la tontine ne convient plus aux participants,
                                alors il faut la supprimer et en recréer une
                                nouvelle.
                              </li>
                            </ul>
                            <b>Cas d’une tontine démarrée :</b>
                            Il n’est pas possible de modifier les paramètres
                            d’une tontine démarrée. Par contre, l’organisateur
                            peut modifier les positions des tontiniers qui n’ont
                            pas encore reçu leur levée, s’ils donnent leur
                            accord.
                          </div>
                        </div>
                      </Collapse>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*faq up*/}
        </section>
        {/*sign up*/}

        {/*Footer Section start*/}
        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-section">
                <p>© {new Date().getFullYear()}, Fait avec ❤️ par Ets Lundasolutions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Footer Section End*/}
      </div>
      {/*blog Section start*/}
    </div>
  );
};

export default FAQ;
