import React, { useEffect } from "react";

const PageNotFound = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="authentication-form not-found">
      <div className="innerpage-decor">
        <div className="innerpage-circle1">
          <img src="assets/images/Testimonial2.png" alt="" />
        </div>
        <div className="innerpage-circle2">
          <img src="assets/images/Testimonial1.png" alt="" />
        </div>
      </div>
      <div className="text-center">
        <h2 className="error-font">
          4<span>0</span>4
        </h2>
        <h2 className="f-bold">NON TROUVÉ</h2>
        <div className="col-md-8 offset-md-2 col-12">
          <h3>
            La page que vous tentez d'atteindre n'est pas disponible
            actuellement. Cela peut être dû au fait que la page n'existe pas ou
            a été déplacée.
          </h3>
        </div>
        <div className="mt-5">
          <a
            href={`/`}
            className="btn btn-custom theme-color theme-color pt-2 pb-2"
          >
            RETOUR À LA PAGE D'ACCUEIL
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
