import React, { useEffect, useState } from "react";
import Menu from "./components/Navbar";
// import { Tilt } from "react-tilt";
import About from "./components/About";
import Feature from "./components/Feature";
import Team from "./components/Team";
// import Blog from "./components/Blog";
// import Price from "./components/Price";
import Testimonial from "./components/Testimonial";
import Contact from "./components/Contact";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import MySlider from "./demo-page/components/MySlider";
import Partners from "./components/parteners";

const HomeOne = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  /*For changing background image by changing the color*/
  const [bgImg, setBgImg] = useState({
    backgroundImage: "url(/assets/images/1.png)",
  });
  const color = localStorage.getItem("color");

  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: "url(/assets/images/1.png)",
      });
    else if (color === "color-2")
      setBgImg({ backgroundImage: "url(/assets/images/color/color-2/1.png)" });
    else
      setBgImg({ backgroundImage: "url(/assets/images/color/color-3/1.png)" });
  }, [color]);

  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style");
  document.body.classList.remove("three");
  document.body.classList.remove("home-style-two");
  return (
    <div>
      {/* Navbar Component*/}
      <Menu homePage="home-one" />
      {/* Home One Section Start */}
      <section id="home" className="home" style={bgImg}>
        <div className="home-decor">
          <div className="home-circle1">
            <img src="/assets/images/main-banner3.png" alt="" />
          </div>
          <div className="home-circle2">
            <img src="/assets/images/main-banner12.png" alt="" />
          </div>
          <div className="home-circle3">
            <img src="/assets/images/main-banner1.png" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="home-contain">
                <div>
                  <img
                    style={{ width: '85px' }}
                    src="/assets/images/french-tontine.png"
                    alt="caption-img"
                    className="caption-img"
                  />
                  <h1>
                    <span className="f-bold f-color">Grâce à</span> <span className="main-title">La</span>
                  </h1>
                  <h1 className="main-title">
                    <span className="f-bold">f</span>rench
                    <span className="f-bold f-color">tontine</span>
                  </h1>

                  <div className="container">
                    <div className="row ">
                      <div className="col-sm-12 col-md-6">
                        <div>
                          <MySlider />
                            <div className="top-margin">
                                <a
                                  href={`#contact `}
                                  className="btn btn-custom theme-color"
                                  role="button"
                                >
                                    Nous rejoindre
                                </a>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <a href="javascript" onClick={(e)=>{e.preventDefault()}}>
                    <img src='/assets/images/appstore.png' alt="appstore" className="store" />
                  </a>
                  <a href="javascript" onClick={(e)=>{e.preventDefault()}}>
                    <img
                      className="ml-10 store"
                      src='/assets/images/play-store.png'
                      alt="play-store"
                    />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-sm-6 offset-lg-1 d-flex justify-content-center align-items-center">
              <div className="home-right">
              <img
                    src="/assets/images/mobile.png"
                    className="img-fluid"
                    alt="mobile"
                  />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home One Section End */}
      {/* About Component*/}
      <About />
      {/*Feature Component*/}
      <Feature />
      {/*ScreenShot Component*/}
      {/* <ScreenShot /> */}
      {/*Team Component*/}
      <Team />
      {/*Blog Component*/}
      {/* <Blog /> */}
      {/*Price Component*/}
      {/* <Price /> */}
      {/*Testimonial Component*/}
      <Testimonial />
      {/*Contact Component*/}
      <Contact />
      {/*Subscription Component*/}
      <Partners />
      {/*Footer Component*/}
      <Subscribe />
      {/*Partners Component*/}
      <Footer />
    </div>
  );
};

export default HomeOne;
