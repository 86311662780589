import React, { useEffect, useState } from "react";
import AboutOurValues from "./AboutOurValues";
import AboutOurVision from "./AboutOurVision";
// import { useHistory } from "react-router";
const About = (props) => {
  const { isOnly = false } = props;

  // const history = useHistory();
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: "url(/assets/images/about-bg.png)",
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: "url(/assets/images/about-bg.png)",
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: "url(/assets/images/color/color-2/about-bg.png)",
      });
    else
      setBgImg({
        backgroundImage: "url(/assets/images/color/color-3/about-bg.png)",
      });
  }, [color]);

  return (
    <>
      <section id="about" className="about" style={bgImg}>
        <div className="about-decor">
          <div className="about-circle1">
            <img src={`/assets/images/team1.png`} alt="team1" />
          </div>
          <div className="about-circle2">
            <img src={`/assets/images/main-banner1.png`} alt="banner1" />
          </div>
        </div>
        <div className="container">
          <div className="row ">
            <div className="col-md-5">
              <div className="about-contain">
                <div>
                  <h2 className="title">
                    {!isOnly && ( <>à propos de la <br /></>)} <span className="main-title">french tontine</span>
                  </h2>

                  <p className="caption-about" style={{ color: '#212529' }}>
                    La French Tontine est un système de digitalisation de la pratique 
                    traditionnelle de l'épargne collective. Sa vision est celle de mettre 
                    en relation des personnes (particuliers et entrepreneurs) de façon 
                    virtuelle dans le but d'effectuer des économies sous forme de cotisations 
                    et d'en bénéficier la somme plus tard.
                  </p>
                  
                  <h2 className="title">
                    <span>Nos Valeurs : </span>
                  </h2>
                  <div className="row sm-mb">
                    <div className="col-6">
                      <ul className="about-style">
                        <li className="abt-hover">
                          <div className="about-icon">
                            <div className="icon-hover">
                              <img
                                src={`/assets/images/icon1.png`}
                                alt="easy-to-customized"
                              />
                            </div>
                          </div>
                          <div className="about-text">
                            <h3>Solidarité</h3>
                          </div>
                        </li>
                        <li className="abt-hover">
                          <div className="about-icon">
                            <div className="icon-hover">
                              <img
                                src={`/assets/images/icon3.png`}
                                alt="easy-to-use"
                              />
                            </div>
                          </div>
                          <div className="about-text">
                            <h3>Confiance</h3>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="about-style">
                        <li className="abt-hover">
                          <div className="about-icon">
                            <div className="icon-hover">
                              <img
                                src={`/assets/images/icon2.png`}
                                alt="Awasome-Design"
                              />
                            </div>
                          </div>
                          <div className="about-text">
                            <h3>Sécurité</h3>
                          </div>
                        </li>
                        <li className="abt-hover">
                          <div className="about-icon">
                            <div className="icon-hover">
                              <img
                                src={`/assets/images/icon4.png`}
                                alt="SEO-Friendly"
                              />
                            </div>
                          </div>
                          <div className="about-text">
                            <h3>Entrepreneuriat</h3>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="d-flex">
                               
                  {!isOnly && <>
                    <div className="top-margin">
                      <a
                        href={`/about-us`}
                        className="btn btn-custom theme-color"
                        role="button"
                      >
                        voir plus
                      </a>
                    </div>
                    {/* <div className="top-margin ml-2">
                                <a
                                  href={`#contact `}
                                  className="btn btn-custom btn-outline-dark bg-white text-dark border border-3"
                                  role="button"
                                >
                                    Rejoindre la french Tontine
                                </a>
                            </div> */}
                  </>}
               
                  </div>
            
                  {isOnly &&    <div className="top-margin ml-2">
                                <a
                                  href={`/home#contact `}
                                  className="btn btn-custom theme-color"
                                  role="button"
                                >
                                    Rejoindre la french Tontine
                                </a>
                            </div> }
                  {isOnly && < img src={`/assets/images/down.png`} style={{ height: 50, marginTop : 40 }} alt="" /> }
                  
                </div>
              </div>
            </div>
            <div className="col-md-7 d-medium-none">
              <div className="about-right">
                <div className="about-phone">
                  <img
                    src={`/assets/images/aboutus.png`}
                    className="img-fluid"
                    alt="aboutus"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {isOnly && <>
        <AboutOurValues />
        <AboutOurVision />
      </>}
    </>
  );
};

export default About;
